<template>
  <v-container>
    <div class="btnContainer">
      <v-btn class="btn" outlined @click="bookNowClick()">NEW GUEST</v-btn>
      <v-btn class="btn" outlined @click="bookNowClick()">EXISTING GUESTS</v-btn>
      <!-- <v-btn class="btn" outlined @click="bookNowClick()">SATURDAY APPT</v-btn> -->
      <!-- <v-btn class="btn" outlined @click="textBtnClick()">TEXT US</v-btn> -->
    </div>
    <div class="btnContainer">
      <!-- <v-btn class="btn" outlined @click="bookNowClick()">BOOK ONLINE</v-btn> -->
      <v-btn class="btn" outlined @click="textBtnClick()">TEXT US</v-btn>
    </div>
    <v-expansion-panels v-model="activePanelIndex">
      <v-expansion-panel v-for="menu in serviceItems" :key="menu.title">
        <v-expansion-panel-header>{{menu.title}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-for="item in menu.services" :key="item.name" class="serviceCard d-flex flex-row justify-space-between" outlined elevation="4" @click="serviceClickHandler()">
            <div class="servicesName">
              <span class="nameSpan">{{item.name}}</span><span v-if="item.description"> - {{item.description}}</span>
              <div v-if="item.details" style="margin-top: 10px">
                <span v-for="detail in item.details" :key="detail.index">
                  <span class="servicesDetailsName">{{detail.name}}:</span>
                  <span class="servicesDetails">{{detail.price}}</span>
                  <br class="d-md-none d-lg-none d-xl-none d-sm-none d-xs-flex">
                </span>
              </div>
            </div>
            <div class="servicesPrice">
              <span v-for="priceItem in item.cost" :key="priceItem.index">
                <span class="costName" v-if="priceItem.name !== undefined">{{priceItem.name}}:</span>
                <span class="costPrice">{{priceItem.price}}<br/></span>
              </span>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
  export default {
    name: 'Services',

    data: () => ({
      activePanelIndex: null,
      serviceItems: [
        {title: 'Cut + Style', services: [
          {name: "Cut + Style", cost: [{price:"$75+", index: 0}], description: "Hair washed, conditioned, cut, blown dry, and styled"}]
        },
        {title: 'Blowout', services: [
          {name: "Blowout", cost: [{price: "$50+", index:0}], description: "Hair washed, conditioned, and blown out dry."},
          ]
        },
        {title: 'Brazilian Blowout', services: [
          {name: "Brazilian Blowout", cost: [{price:"$200+", index:0}], description: "Cut down on drying time, extend the life of your color, and get frizz free  hair for up to 12 weeks! Brazilian Blowout is customizable to the results you’re looking for whether you want to keep your curls or go smooth and sleek. Hair will be washable the same day."},
          {name: "Express Brazilian Blowout", cost: [{price:"$125+", index:0}], description: "Running short on time? Not sure if you want the full treatment? The Express Brazilian Blowout has you covered. The same benefits as a Brazilian, in less time. This treatment will last up to 4 weeks."}]
        },
        {title: 'Color', services: [
          {name: "Root Touch-Up", description: "Single process 4-6 week base color deposited at the new growth.",
            cost: [
              {price: "$70+", index:2}
          ]},
          {name: "Roots Touch-Up Blowout", description: "",
            cost: [
              {price: "$120+", index:2}
          ]},
          {name: "Roots Touch-Up Cut", description: "",
            cost: [
              {price: "$140+", index:2}
          ]},
          {name: "Glaze", description: "Helps to refresh and balance faded ends for deeper tones, neutralize unwanted tones in blondes, or create bolder hues without the commitment of permanent color. All while adding moisture and shine.",
            cost: [
              {price: "$55+", index:2}
          ]}]
        },
        {title: 'Highlight', services: [
          {name: "Classic Foils", description: "Adding Brightness and multi tonal dimension with foils, including color gloss and strengthening treatment. ✨", 
            details: [
              {name: "Face Frame", price: "$165+", index:0},
              {name: "Partial", price: "$195+", index:1},
              {name: "Full Foil", price: "$220+", index:2}
          ]},
          {name: "Creative", description: "Balayage, Teasy-lights, Low-Lights, Baby-lights, or a combo of everything! Including color gloss and strengthening treatment.",
            details: [
              {name: "Partial", price: "$165+", index:0},
              {name: "Full", price: "$270+", index:1},
          ]}]
        },
        {title: 'Hair Extension Services', services: [
          {name: "Weft 1 Row", cost: [{price: "$150-$225", index:0}], description: ""},
          {name: "Weft 2 Rows", cost: [{price: "$300-$450", index:0}], description: ""},
          {name: "Weft 3 Rows", cost: [{price: "$400-$550", index:0}], description: ""},
          {name: "I-Tip K-Tip Tape-in", cost: [{price: "$125/pk", index:0}], description: ""},]
        }
      ]
    }),
    methods: {
      customColor() {
        return 'darkslateblue--text serviceTitle';
      },
      serviceClickHandler () {
        window.open('https://www.vagaro.com/houseoffringe/book-now', '_blank')
      },
      bookNowClick () {
        window.open('https://www.vagaro.com/houseoffringe/book-now', '_blank')
      },
      textBtnClick () {
        window.open('sms:9089076062', '_blank')
      }
    },
    watch: {
      activePanelIndex: function() {
        // nav to the menu that is opened
        setTimeout(() => {
          if (this.activePanelIndex) {
            this.$vuetify.goTo(this.activePanelIndex);
          }
        }, 500);
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .menuCard {
    margin-top: 10px;
    /*card container color="#B3B3B3"*/
  }
  .serviceTitle {
    font-size: 30px;
    margin-left: 10px;
  }
  .serviceCard {
    /*width: 300px;*/
    margin: 10px;
    background-color: #EEEEEE;
    border-radius: 5px;
    cursor: pointer;
  }
  .serviceContent {
    height: 100%;
  }
  .servicesName {
    font-size: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    white-space: break-spaces;
    margin-bottom: 10px;
  }
  .nameSpan {
    font-weight: bold;
  }
  .serviceDesc {
    padding: 10px;
    margin-top: 10px;
    white-space: pre-line;
  }
  .servicesPrice {
    font-size: 16px;
    text-align: right;
    font-weight: bold;
    margin: 5px 10px 10px 5px;
  }
  .servicesDetailsName {
    font-size: 13px;
    font-weight: bold;
    margin-right: 5px;
  }
  .servicesDetails {
    font-size: 13px;
    margin-right: 10px;
  }
  .costName {
    margin-right: 3px;
    white-space: nowrap;
  }
  .costPrice {
    margin-right: 5px;
  }
  .btnContainer {
    text-align: center;
    padding: 10px 0px;
  }
  .btn {
    color: #C0B596;
    margin: 2px 8px;
  }
</style>